import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  Action,
  BaseEvent,
  EmployeeDetails,
  Employment,
  EmploymentEvent,
  MasterEntities
} from '../../master.entities.models';
import {ToastrService} from 'ngx-toastr';
import {MasterEntitiesService} from '../../master.entities.service';
import {SpinnerService} from '../../../spinner/spinner.service';
import {MasterEmployeeService} from '../../master.employee.service';

@Component({
  selector: 'app-employments-tab',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './employments-tab.component.html',
  styleUrls: ['./employments-tab.component.css']
})
export class EmploymentsTabComponent implements OnInit, OnChanges {

  @Input() employments: Array<Employment>;
  @Input() employee: EmployeeDetails;
  @Output() employmentTabEventEmitter = new EventEmitter<BaseEvent>();

  newEmploymentFormVisible = false;
  newEmployment: Employment;

  masterEntities: MasterEntities;

  constructor(
              private route: ActivatedRoute,
              private router: Router,
              private toastrService: ToastrService,
              private spinnerService: SpinnerService,
              private masterEntitiesService: MasterEntitiesService,
              private masterEmployeeService: MasterEmployeeService
              ) { }

  ngOnInit() {
    this.masterEntities =  {
      locations: this.masterEntitiesService.getLocations(),
      departments: this.masterEntitiesService.getDepartments(),
      positions: this.masterEntitiesService.getPositions(),
      jobTitles: this.masterEntitiesService.getJobTitles(),
      employeeStatuses: this.masterEntitiesService.getEmployeeStatuses(),
      employeeTypes: this.masterEntitiesService.getEmployeeTypes(),
      benefitClasses: this.masterEntitiesService.getBenefitClasses(),
      payTypes: this.masterEntitiesService.getPayTypes(),
      payFrequencies: this.masterEntitiesService.getPayFrequencies(),
      overtimeStatuses: this.masterEntitiesService.getOvertimeStatuses(),
      aahaAccounts: this.masterEntitiesService.getAahaAccounts()
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.employments = changes.employments.currentValue as Array<Employment>;
    this.sortEmployments();
    this.employee = changes.employee.currentValue;
  }

  sortEmployments() {
    this.employments.sort((a, b) => {
      const aStatus = a.employeeStatus?.name;
      const aEmployeeType = a.employeeType?.name;
      const bStatus = b.employeeStatus?.name;
      const bEmployeeType = b.employeeType?.name;
      let result = 0;
      if (aStatus === 'Active' && aEmployeeType === 'Full-Time') {
        result = -1;
      } else if (bStatus === 'Active' && bEmployeeType === 'Full-Time') {
        result = 1;
      } else if (aStatus === 'Active') {
        result = -1;
      } else if (bStatus === 'Active') {
        result = 1;
      } else {
        result = a.hiredOn > b.hiredOn ? -1 : 1
      }
      return result;
    });
  }

  public isNewEmploymentFormVisible(){
    return this.newEmploymentFormVisible;
  }

  onEmploymentEvent($event: EmploymentEvent) {
    this.employmentTabEventEmitter.emit({action: Action.UPDATE})
  }
}
