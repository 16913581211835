<div class="container">
  <div class="row style-row-gap-10">
    <div [hidden]="!newEmploymentFormVisible">
      <app-employment [employee]="employee"
                      [employment]="newEmployment"
                      [masterEntities]="masterEntities"
                      (employmentEventEmitter)="onEmploymentEvent($event)"></app-employment>
      <br/>
    </div>
    <p *ngIf="employments.length == 0 && !isNewEmploymentFormVisible()">No employments found</p>

    <mat-accordion class="employments-headers-align">
      <mat-expansion-panel *ngFor="let employmentInfo of employments; let i=index" [expanded]="i === 0"
                           (opened)="i === 0">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{employmentInfo.rarebreedEmployeeId}} @ {{ employmentInfo.location?.name }}
            <small style="color: gray">&nbsp;({{ employmentInfo.employeeStatus?.name }}<span
              *ngIf="employmentInfo.employeeType?.name">|</span>{{ employmentInfo.employeeType?.name }})</small>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <app-employment [employee]="employee"
                        [employment]="employmentInfo"
                        [masterEntities]="masterEntities"
                        (employmentEventEmitter)="onEmploymentEvent($event)"></app-employment>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

