import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {ActivatedRoute, Router} from '@angular/router';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UnmatchedRecord} from './unmatched-records.models';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {merge, of as observableOf} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {UnmatchedRecordsService} from './unmatched-records.service';
import {SearchFilters} from '../employee/list/employee-list.model';
import {EmployeeListFiltersService} from '../employee/list/employee-list.filters.service';
import {MatDialog} from '@angular/material/dialog';
import {MatchDialog} from './modals/view/match-dialog';
import {GlobalConstants} from '../global-constants';

@Component({
  selector: 'app-unmatched-records',
  templateUrl: './unmatched-records.component.html',
  styleUrls: ['./unmatched-records.component.css']
})
export class UnmatchedRecordsComponent implements AfterViewInit, OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  filters: SearchFilters;
  displayedColumns: string[] = ['externalSystemId', 'externalRarebreedId', 'originType.name', 'fullName', 'locationName', 'reason', 'employeeStatus', 'star'];
  data: UnmatchedRecord[] = [];
  dataSource: MatTableDataSource<UnmatchedRecord>;
  isLoadingResults = true;
  resultsLength = 0;
  pageSize = 25;
  readonly LOCAL_STORAGE_FILTERS_SUFFIX = 'unmatched-records-3';

  constructor(public route: ActivatedRoute,
              public router: Router,
              public unmatchedRecordsService: UnmatchedRecordsService,
              private _snackBar: MatSnackBar,
              public dialog: MatDialog,
              private filtersService: EmployeeListFiltersService) {
    this.initFilters();
  }

  ngOnInit() {
    this.filtersService.loadLocations(this.filters);
  }

  private initFilters() {
    this.filters = this.filtersService.getFilters(this.LOCAL_STORAGE_FILTERS_SUFFIX);
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          // tslint:disable-next-line:max-line-length
          return this.unmatchedRecordsService.findAll(this.paginator.pageIndex, this.paginator.pageSize,
            this.sort.active, this.sort.direction, this.filtersService.buildSearchParam(this.filters))
            .pipe(catchError(() => observableOf([])));
        }),
        map(result => {
          this.isLoadingResults = false;
          // @ts-ignore
          this.resultsLength = result?.totalElements;
          // @ts-ignore
          return result?.content;
        }),
      )
      .subscribe(data => (this.asDataSource(data)));
  }

  asDataSource(data: UnmatchedRecord[]) {
    this.data.splice(0, this.data.length);
    Array.prototype.push.apply(this.data, data);
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.sort = this.sort;
  }

  getExternalId(record: UnmatchedRecord) {
    let result = '';
    if (record.originType.code === 'PAYLOCITY') {
      result = record.originalEntity?.companyId + ' - ' + record.originalEntity?.originalId;
    } else {
      result = record.originalEntity?.originalId
    }
    return result;
  }

  updateFilter() {
    this.filtersService.saveFilters(this.filters, this.LOCAL_STORAGE_FILTERS_SUFFIX);
    this.reset();
  }

  reset() {
    this.paginator.pageIndex = 0;
    this.paginator._changePageSize(this.paginator.pageSize);
  }

  resetGrid() {
    this.filtersService.resetFilters(this.LOCAL_STORAGE_FILTERS_SUFFIX);
    this.initFilters();
    this.reset();
  }

  calculateActiveFiltersCount() {
    let result = 0;
    for (const [key, value] of Object.entries(this.filters)) {
      if (value.value) {
        result++;
      }
    }
    return result;
  }

  openMatchDialog(record: UnmatchedRecord): void {
    let content = `To match this record, visit this employee\'s profile in <a href="${record.originalEntity.link}" target="_blank">${record.originType.name}</a> system and enter Rarebreed ID in corresponding field`;
    if (record.originType.code === 'NETSUITE') {
      content = `Matching is not yet supported for ${record.originType.name} employees`;
    } else if (record.originType.code === 'ACTIVE_DIRECTORY'){
      content = 'Manual matching workflow is not supported for Active Directory employees. The system was not able to match this record automatically using work email';
    } else if (record.originType.code === 'VETSOURCE') {
      content = null;
    } else if (record.sourceValue) {
      content = `Employee’s original location is not matched to a master location. Go to Master Data <a href="${GlobalConstants.masterURL}" target="_blank">(direct link)</a>`;
    }
    const dialogRef = this.dialog.open(MatchDialog, {
      width: '800px',
      height: '670px',
      data: {
        link: record.originalEntity.link,
        systemName: record.originType.name,
        systemCode: record.originType.code,
        fullName: record.fullName,
        searchQuery: record.fullName,
        originalId: record.originalEntity.originalId,
        selectedIndex: 0,
        locationId: record.originalEntity.companyId,
        content
      },
    });

    // tslint:disable-next-line:ter-arrow-parens
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
          const index = this.dataSource.data.findIndex(item => item.originalEntity.originalId === record.originalEntity.originalId);
          this.dataSource.data.splice(index, 1);
          this.resultsLength -= 1;
          this.table.renderRows();
          this.dataSource._updateChangeSubscription();
      }
    });
  }
}
